body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

/* Custom highlighter color */
::selection {
  background: orange;
  color: black;
}

/* For Mozilla Firefox compatibility */
::-moz-selection {
  background: orange;
  color: black;
}

.Example input,
.Example button {
  font: inherit;
}

.Example header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.Example header h1 {
  font-size: inherit;
  margin: 0;
}

/* .Example__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.Example__container__load {
  margin-top: 1em;
  color: white;
}

.Example__container__document {
  width: 100%;
  max-width: calc(100% - 2em);
  margin: 1em 0;
}

.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  margin: 1em 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.Example__container__document .react-pdf__message {
  padding: 20px;
  color: white;
} */


/* styles for file-input-container */

.file-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-top: 40px;
  width: auto;
  background-color: #F9F9FA;
  box-shadow: 0 1px 0 #CCCCCC;
  height: calc(100vh - 80px - 80px);
}

.document-url {
  width: 75%;
  margin-bottom: 18px;

}

.dropzone-container {
  height: 200px;
  /* max-height: 300px; */
  width: 75%;
}

/* App.css */

/* css from project parcel2samplepdf */
.progress-bar {
  width: 100%;
  text-align: center;
  height: 4px;
}

.progress-bar .progress {
  position: relative;
  color: #fff;
  width: 100%;
  background: #7c99d1;
  border-radius: none;
  text-align: center;
  z-index: 10000;
}

.progress-bar .progress .progress-percent {
  height: 4px;
  position: absolute;
  background: -webkit-gradient(linear, right top, left top, color-stop(43.94%, #fd7243), to(#fdb343));
  background: -o-linear-gradient(right, #fd7243 43.94%, #fdb343 100%);
  background: linear-gradient(270deg, #fd7243 43.94%, #fdb343);
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10001;
}

.thumbnail {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;  
}

.thumbnail.skeleton{
  margin-bottom: 20px;
}

.thumbnail .placeholder,
.thumbnail img {
  border: 3px solid #fff;
  width: 100%;
  cursor: pointer;
}

.thumbnail .placeholder.focused,
.thumbnail .placeholder:hover,
.thumbnail img.focused,
.thumbnail img:hover {
  border: 3px solid #7c86a5;
}

.thumbnail .page-number {
  margin-top: 4px;
}

/* .pdf-thumbnail-bar.hide {
  margin-left: -190px;
} */


.viewer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  margin-top: 40px;
  background-color: #f1f3f7;
  position:fixed;
}

.pdf-viewer-sidebar {
  top: 0;
  min-height: 100vh;
  height: 100%;
  width: 240px;
  height: 100vh;
  overflow: auto;
  background-color: #f1f3f7;
  border-right: 1px solid #dce0e3;
  padding: 22px 24px;
  transition: all 0.5s;
}
.pdf-viewer-sidebar.hidden {
  display: none;
}
.document-container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }

  /* Add the following styles to the react-pdf defaults */
.react-pdf__Page{
  margin-bottom: 20px
}

.react-pdf__Thumbnail{
  margin-bottom: 20px;
}

.toolbar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  cursor: default;
  width: 100%;
}

.toolbar-container {
  width: 100%;
  position: relative;
  height: 40px;
  background-color: #F9F9FA;
  box-shadow: 0 1px 0 #CCCCCC;
}

.toolbar-viewer {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0px 4px;
}

.toolbar-viewer-middle {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* align-items: center; */
}

.toolbar-viewer-right {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.toolbar-viewer-left {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}


.outer-container {  
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}


/* .toolbarButton.toggled, .splitToolbarButton.toggled > .toolbarButton.toggled, .secondaryToolbarButton.toggled {
  background-color: var(--toggled-btn-bg-color);
  color: var(--toggled-btn-color);
} */

/* .toolbarButton, .dropdownToolbarButton, .secondaryToolbarButton, .overlayButton {
  min-width: 16px;
  margin: 2px 1px;
  padding: 2px 6px 0;
  border: none;
  border-radius: 2px;
  color: var(--main-color);
  font-size: 12px;
  line-height: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
  box-sizing: border-box;
}

.toolbarButton, .secondaryToolbarButton, .overlayButton {
  border: 0 none;
  background: none;
  width: 28px;
  height: 28px;
} */

/* .fab {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
}; */

